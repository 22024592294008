import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { InvoiceFeeSchema } from './InvoiceFee';
import { BILLING_TYPES, PolicySchema } from './Policy';
import queryBuilderToQueryString from '../Helpers/queryBuilderToQueryString';

const INVOICE_STATUSES = [
  'draft',
  'receivable_pending',
  'receivable_partial',
  'receivable_paid',
  'payable_pending',
  'payable_partial',
  'payable_paid',
  'fully_settled',
  'overpaid',
  'has_unsettled_fees',
  'zero_balance',
  'anomaly',
] as const;

export const InvoiceSchema = z.object({
  id: z.number(),
  billing_type: z.enum(BILLING_TYPES).nullable(),
  due_date: z.string().nullable(),
  show_commissions: z.boolean(),
  show_mga_commissions: z.boolean(),
  note: z.string().nullish(),
  client_id: z.number(),
  policy_id: z.number().nullable(),

  receivable_balance: z.number().int(),
  received_amount: z.number().int(),
  payable_balance: z.number().int(),
  paid_amount: z.number().int(),

  producer_id: z.number().int().nullish(),
  fee_total: z.number().int(),
  status: z.enum(INVOICE_STATUSES).nullish(),
  is_sent: z.boolean(),
  is_draft: z.boolean(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  model_type: z.string().nullish(),

  fees: z.array(InvoiceFeeSchema).nullish(),
  policy: PolicySchema.nullish(),
});

export type Invoice = z.infer<typeof InvoiceSchema>;

type InvoicePayload = {
  id: number;
  form: Partial<Invoice>;
  params: Record<string, any>;
};

async function index({ params }: Pick<InvoicePayload, 'params'>) {
  const response = await axios.get(`/api/invoices?${queryBuilderToQueryString(params)}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: z.object({
        data: z.array(InvoiceSchema),
      }),
    })
    .parseAsync(response);
}

async function find({ id }: Pick<InvoicePayload, 'id'>) {
  const response = await axios.get(`/api/invoices/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function create({ form }: Pick<InvoicePayload, 'form'>) {
  const response = await axios.post(`/api/invoices`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function update({ id, form }: Pick<InvoicePayload, 'id' | 'form'>) {
  const response = await axios.put(`/api/invoices/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function approveDraft({ id }: Pick<InvoicePayload, 'id'>) {
  const response = await axios.put(`/api/invoices/${id}/approve-draft`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceSchema,
    })
    .parseAsync(response.data);
}

async function trash({ id }: Pick<InvoicePayload, 'id'>) {
  const response = await axios.delete(`/api/invoices/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  // Check if the status is 204
  return response.status === 204;
}

export default {
  index,
  find,
  create,
  update,
  approveDraft,
  trash,
};
